export const SIGN_UP_LINK =
  "https://sincbusiness.page.link/?link=https://users.sinc.business/login/signup/&apn=com.sinc.sincandroid&isi=1253126382&ibi=com.sincbusiness.sinc&efr=1"
export const LOGIN_LINK = "https://users.sinc.business/mysinc"
export const MYOB_SIGN_UP_LINK =
  "https://login.myob.com/account/RegisterOrganizationAsNewUser?branding_id=AU&client_id=shopping-cart-prod&nonce=d5ef905f-592e-4cbc-b0f4-110839968bbc&redirect_uri=%2Foauth2%2Fauthorize%3Fscope%3Dopenid%2Bphone%26response_type%3Did_token%26response_mode%3Dform_post%26client_id%3Dshopping-cart-prod%26redirect_uri%3Dhttps%3A%2F%2Fdxp-bettacart-bff-production.svc.platform.myob.com%2Fauthentication%2Fresponse%26state%3D%7B%22productId%22%3A%2252%22%2C%22exp%22%3A%22deep-linking%22%2C%22pc%22%3A%22ONLINEONLY50PCTOFF%22%2C%22intcid%22%3A%22oldeasignin%22%2C%22region%22%3A%22AU%22%2C%22transactionType%22%3A%22TRIAL%22%7D%26nonce%3Dd5ef905f-592e-4cbc-b0f4-110839968bbc"
export const SIGN_UP_LINK_FROM_GUSTO =
  "https://users.sinc.business/referral/w0RKL79vCY25WAz8WCc5"

export const APP_STORE_LINK = "https://sincbusiness.page.link/ios-web"
export const PLAY_STORE_LINK = "https://sincbusiness.page.link/android-web"
