import React from "react"
import { Link } from "gatsby"
import logo from "../../assets/sinc-workforce-logo.svg"
import Links from "../../constants/links"

// fallback in case that page doesnt have the attributed links being passed to it
import { SIGN_UP_LINK, LOGIN_LINK } from "../../constants/linkConstants"

const FullSizeTopMenu = ({
  intercomInitialised,
  initialiseIntercom,
  signupLink,
  loginLink,
  alternativeLandingPageUrl,
}) => (
  <nav className="navbar large-screen">
    <div className="nav-center ">
      <div className="nav-header">
        <Link to="/" className="nav-logo">
          <img src={logo} className="top-logo" alt="sinc workforce logo" />
        </Link>
      </div>
      <div className="navbar-right-wrapper">
        <Links
          intercomInitialised={intercomInitialised}
          initialiseIntercom={initialiseIntercom}
          alternativeLandingPageUrl={alternativeLandingPageUrl}
          styleClass="nav-links"
        />

        <button className="btn" style={{ marginRight: 15 }}>
          <a href={signupLink || SIGN_UP_LINK} id="signup_header_default">
            Get Started
          </a>
        </button>
        <button className="btn btn-ghost">
          <a id="login_header_default" href={loginLink || LOGIN_LINK}>
            Login
          </a>
        </button>
      </div>
    </div>
  </nav>
)

export default FullSizeTopMenu
