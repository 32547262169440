import React from "react"
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa"
import SocialIcon from "./socialIcon"

const SocialLinks = ({ footer }) => {
  const listStyleClass = footer ? "social-icons footer" : "social-icons"
  const text = "Follow Us"
  return (
    <>
      {footer ? (
        <p>{text}:</p>
      ) : (
        <span style={{ marginBottom: "16px", display: "inline-block" }}>
          {text}
        </span>
      )}
      <ul className={listStyleClass}>
        <li>
          <SocialIcon
            Icon={FaFacebookF}
            footer={footer}
            link="https://www.facebook.com/sincbusiness/"
            style={!footer && { marginLeft: 0 }}
          />
        </li>
        <li>
          <SocialIcon
            Icon={FaInstagram}
            footer={footer}
            link="https://www.instagram.com/sinc_employee_time_clock/"
          />
        </li>
        <li>
          <SocialIcon
            Icon={FaYoutube}
            footer={footer}
            link="https://www.youtube.com/channel/UCedmS0VR52nbhIjl6r6NLQg"
          />
        </li>
        <li>
          <SocialIcon
            Icon={FaTwitter}
            footer={footer}
            link="https://twitter.com/sincbusiness"
          />
        </li>
      </ul>
    </>
  )
}
export default SocialLinks
