import React from "react"

export default ({ Icon, id, link, footer, style = {} }) => {
  const colorClass = footer ? "footer-icon" : "onpage-icon"
  return (
    <a
      className="social-icon"
      style={{ ...style }}
      href={link}
      id={id}
      target="_blank"
    >
      <Icon className={colorClass} />
    </a>
  )
}
