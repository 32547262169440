import React from "react"
import { Link } from "gatsby"
import MenuIcon from "./menuIcon"
import logo from "../../assets/sinc-workforce-logo.svg"

// fallback in case that page doesnt have the attributed links being passed to it
import { SIGN_UP_LINK, LOGIN_LINK } from "../../constants/linkConstants"

const SmallSizeTopMenu = ({ toggle, isOpen, signupLink, loginLink }) => (
  <nav className="navbar small-screen">
    <div className="nav-center ">
      <div className="nav-header">
        <Link to="/" className="nav-logo">
          <img
            className="top-logo small"
            src={logo}
            alt="sinc workforce logo"
          />
        </Link>
      </div>
      <div className="navbar-right-wrapper small-screen">
        <div>
          <button className="btn" style={{ marginRight: 15 }}>
            <a href={signupLink || SIGN_UP_LINK} id="signup_header_default">
              Get Started
            </a>
          </button>
          <button className="btn btn-ghost">
            <a id="login_header_default" href={loginLink || LOGIN_LINK}>
              Login
            </a>
          </button>
        </div>

        <MenuIcon nav={isOpen} onClick={toggle}>
          <div />
          <div />
          <div />
        </MenuIcon>
      </div>
    </div>
  </nav>
)

export default SmallSizeTopMenu
