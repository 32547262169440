import React from "react"
import { Link } from "gatsby"
import MenuIcon from "./menuIcon"
import smallLogo from "../../assets/sinc-logo-only.svg"

// fallback in case that page doesnt have the attributed links being passed to it
import { SIGN_UP_LINK, LOGIN_LINK } from "../../constants/linkConstants"

const SmallerSizeTopMenu = ({ toggle, isOpen, signupLink, loginLink }) => (
  <nav className="navbar smaller-screen">
    <div className="nav-center ">
      <div className="nav-header">
        <Link to="/" className="nav-logo">
          <img
            className="top-logo small"
            src={smallLogo}
            alt="sinc workforce logo"
          />
        </Link>
      </div>
      <div className="navbar-right-wrapper small-screen">
        <div>
          <button className="btn smallBtn" style={{ marginRight: 10 }}>
            <a
              href={signupLink || SIGN_UP_LINK}
              id="signup_header_default"
              className="lessPadding"
            >
              Get Started
            </a>
          </button>
          <button className="btn btn-ghost smallBtn">
            <a
              id="login_header_default"
              href={loginLink || LOGIN_LINK}
              className="lessPadding"
            >
              Login
            </a>
          </button>
        </div>

        <MenuIcon nav={isOpen} marginLeft={1} onClick={toggle}>
          <div />
          <div />
          <div />
        </MenuIcon>
      </div>
    </div>
  </nav>
)

export default SmallerSizeTopMenu
