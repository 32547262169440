import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Navbar from "./navbar"
import Footer from "./footer"
import Intercom from "react-intercom"
import { ScreenClassProvider } from "react-grid-system"

const Layout = ({
  children,
  signupLink,
  loginLink,
  alternativeLandingPageUrl,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [intercomInitialised, setIntercomInitialised] = React.useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const handleInitialised = () => {
    setIntercomInitialised(true)
  }

  // initialise intercom either on scroll or after a set time. This is to stop it slowing down page load speed
  useEffect(() => {
    window.addEventListener("scroll", handleInitialised)
    return () => window.removeEventListener("scroll", handleInitialised)
  }, [])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      handleInitialised()
    }, 1500)
    // will clear the timeout on ***next*** render!
    return () => clearTimeout(timer)
  })

  return (
    <ScreenClassProvider>
      <Navbar
        isOpen={isOpen}
        toggle={toggle}
        intercomInitialised={intercomInitialised}
        initialiseIntercom={handleInitialised}
        loginLink={loginLink}
        signupLink={signupLink}
        alternativeLandingPageUrl={alternativeLandingPageUrl}
      />
      <main>{children}</main>
      <Footer />
      {intercomInitialised ? <Intercom appID="fiu1suec" /> : <div />}
    </ScreenClassProvider>
  )
}

Layout.propTypes = {
  searchParams: PropTypes.shape({
    utm_campaign: PropTypes.string,
    utm_source: PropTypes.string,
    utm_medium: PropTypes.string,
  }),
  alternativeLandingPageUrl: PropTypes.string,
}

export default Layout
