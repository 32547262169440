import React from "react"
import styled from "styled-components"

const MenuIcon = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 2rem;
  background: transparent;
  margin-left: ${({ marginLeft }) =>
    marginLeft ? marginLeft + "rem" : "2rem"};
  border: none;
  cursor: pointer;
  z-index: 5;
  div {
    width: 2rem;
    height: 0.2rem;
    background: white;
    border-radius: 5px;
    transform-origin: 2px;
    position: relative;
    transition: all 300ms;
    left: ${({ nav }) => (nav ? "5px" : "0px")};
    :first-child {
      transform: ${({ nav }) => (nav ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ nav }) => (nav ? "0" : "1")};
    }

    :nth-child(3) {
      transform: ${({ nav }) => (nav ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

export default MenuIcon
