import React from "react"
import { Link } from "gatsby"
import { Col } from "react-grid-system"
import {
  FaExclamationTriangle,
  FaBullhorn,
  FaUser,
  FaTable,
} from "react-icons/fa"

const ResourceLinks = () => (
  <Col
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      paddingTop: "2rem",
      textAlign: "left",
    }}
    lg={4}
    md={6}
    sm={12}
  >
    <p>Free Small Business Tools:</p>
    <Link to="/tools/free-ohsa-safety-signs-for-download">
      <div className="flex">
        <p>
          <FaExclamationTriangle className="resource-menu-icon" />
        </p>
        <p>Free OSHA Safety Signs</p>
      </div>
    </Link>
    <Link to="/tools/diy-safety-system">
      <div className="flex">
        <p>
          <FaBullhorn className="resource-menu-icon" />{" "}
        </p>

        <p>DIY Health And Safety System</p>
      </div>
    </Link>
    <Link to="/tools/hr-tool-kit">
      <div className="flex">
        <p>
          <FaUser className="resource-menu-icon" />
        </p>
        <p>HR Tool Kit</p>{" "}
      </div>
    </Link>
    <Link to="/tools/free-excel-construction-templates">
      <div className="flex">
        <p>
          <FaTable className="resource-menu-icon" />
        </p>
        <p>Free Excel Construction Templates</p>{" "}
      </div>
    </Link>
  </Col>
)

export default ResourceLinks
