import React from "react"
import PropTypes from "prop-types"

const InnerContentContainer = ({ children, center, style }) => {
  const wrapperClass = `inner-content-container ${center ? "center" : ""}`
  return (
    <div style={style} className={wrapperClass}>
      {children}
    </div>
  )
}

InnerContentContainer.propTypes = {
  blue: PropTypes.bool,
  center: PropTypes.bool,
}

export default InnerContentContainer
