import React from "react"
import { Row } from "react-grid-system"
import SocialLinks from "../socialLinks"
import InnerContentContainer from "../layout/innerContentContainer"
import Column from "../layout/column"
import CoreSiteLinks from "./coreSiteLinks"
import ResourceLinks from "./resourceLinks"

const Footer = () => {
  return (
    <footer className="footer">
      <InnerContentContainer
        style={{
          padding: "1rem 2rem 0px",
        }}
      >
        <Row style={{ width: "100%" }}>
          <CoreSiteLinks />
          <ResourceLinks />
        </Row>
        <Row className="footer-bottom-section no-horizontal-margin">
          <Column md={6} sm={12} className="copyright-footer-column">
            <p>
              &copy;{new Date().getFullYear()} SINC Business Corporation. All
              rights reserved.
            </p>
          </Column>
          <Column className="social-footer-column" md={6} sm={12}>
            <SocialLinks footer />
          </Column>
        </Row>
      </InnerContentContainer>
      ;
    </footer>
  )
}

export default Footer
