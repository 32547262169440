import React from "react"
import Links from "../../constants/links"
import { IoMdClose } from "react-icons/io"
const Sidebar = ({
  isOpen,
  toggle,
  initialiseIntercom,
  intercomInitialised,
  alternativeLandingPageUrl,
}) => {
  return (
    <aside className={`sidebar ${isOpen ? "showSidebar" : ""}`}>
      <button className="close-btn" onClick={toggle}>
        <IoMdClose />
      </button>
      <div className="sidebar-container">
        <Links
          alternativeLandingPageUrl={alternativeLandingPageUrl}
          intercomInitialised={intercomInitialised}
          initialiseIntercom={initialiseIntercom}
          styleClass="sidebar-links"
          onClick={toggle}
        />
      </div>
    </aside>
  )
}

export default Sidebar
