import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Col } from "react-grid-system"

const query = graphql`
  {
    allStrapiIntegrations {
      nodes {
        slug
        internalLinkTitle
      }
    }
  }
`

const CoreSiteLinks = () => {
  const {
    allStrapiIntegrations: { nodes: integrations },
  } = useStaticQuery(query)
  return (
    <Col
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingTop: "2rem",
        textAlign: "left",
      }}
      lg={4}
      md={6}
      sm={12}
    >
      <Link to="/blog">
        <p>Blog</p>
      </Link>
      <Link to="/legal/privacy">
        <p>Privacy</p>
      </Link>
      <Link to="/legal/terms-of-service">
        <p>Terms Of Service</p>
      </Link>
      {integrations.map(integration => (
        <Link key={integration.slug} to={`/integrations/${integration.slug}`}>
          <p>{integration.internalLinkTitle}</p>
        </Link>
      ))}
    </Col>
  )
}

export default CoreSiteLinks
