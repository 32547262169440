import React from "react"
import FullSizeTopMenu from "./fullSizeTopMenu"
import SmallSizeTopMenu from "./smallSizeTopMenu"
import SmallerSizeTopMenu from "./smallerSizeTopMenu"
import Sidebar from "./sidebar"

const Navbar = ({
  toggle,
  isOpen,
  intercomInitialised,
  initialiseIntercom,
  loginLink,
  signupLink,
  alternativeLandingPageUrl,
}) => {
  return (
    <>
      <FullSizeTopMenu
        initialiseIntercom={initialiseIntercom}
        intercomInitialised={intercomInitialised}
        loginLink={loginLink}
        signupLink={signupLink}
        alternativeLandingPageUrl={alternativeLandingPageUrl}
      />
      <SmallSizeTopMenu
        loginLink={loginLink}
        signupLink={signupLink}
        toggle={toggle}
        isOpen={isOpen}
        alternativeLandingPageUrl={alternativeLandingPageUrl}
      />
      <SmallerSizeTopMenu
        loginLink={loginLink}
        signupLink={signupLink}
        toggle={toggle}
        isOpen={isOpen}
        alternativeLandingPageUrl={alternativeLandingPageUrl}
      />
      <Sidebar
        toggle={toggle}
        isOpen={isOpen}
        initialiseIntercom={initialiseIntercom}
        intercomInitialised={intercomInitialised}
        alternativeLandingPageUrl={alternativeLandingPageUrl}
      />
    </>
  )
}

export default Navbar
