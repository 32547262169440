import React from "react"
import { IntercomAPI } from "react-intercom"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Links = ({
  styleClass,
  children,
  initialiseIntercom,
  intercomInitialised,
  onClick,
  alternativeLandingPageUrl,
}) => {
  return (
    <ul className={styleClass}>
      <li onClick={onClick}>
        <AnchorLink
          stripHash
          to={
            alternativeLandingPageUrl
              ? `/${alternativeLandingPageUrl}/#features`
              : "/#features"
          }
          className="page-link"
        >
          Features
        </AnchorLink>
      </li>
      <li onClick={onClick}>
        <AnchorLink
          stripHash
          to={
            alternativeLandingPageUrl
              ? `/${alternativeLandingPageUrl}/#pricing`
              : "/#pricing"
          }
          className="page-link"
        >
          Pricing
        </AnchorLink>
        {children}
      </li>
      <li onClick={onClick}>
        <a
          href="http://help.sinc.business/"
          className="page-link"
          target="_blank"
        >
          Guides
        </a>
      </li>
      <li
        onClick={() => {
          if (intercomInitialised) {
            IntercomAPI("show")
          } else {
            initialiseIntercom()
            setTimeout(() => {
              IntercomAPI("show")
            }, 1000)
          }
          if (onClick) {
            onClick()
          }
        }}
      >
        <a id="contact_link_main" className="page-link">
          Contact
        </a>
      </li>
    </ul>
  )
}

export default Links
